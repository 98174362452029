import { Button } from "react-bootstrap";

const InternalForm = (props) => {
    return (
      <div id="step2" className="step-form">
        <div id="internal-login-form">
        <img
            src="up-logo-light.png"
            alt="Ultimate Performance"
            className="logo pb-4"
            width="150px"
        />
        <p className="text-light">Login with your corporate credentials.</p>
          <Button onClick={props.handleInternalLoginClick} block variant="up-primary" type="submit" className="bg-secondary p-2">
          
          {props.state.loading === true ? (
              <span>
              <img src="oval.svg" alt="Loading" width="30" />
              </span>
          ) : (
              <span><img src="microsoft.png" alt="Microsoft Logo" width="20" className="mr-2" /> Log in at upfitness.com</span>
          )}
          </Button>
        </div>
        <p className="mt-4">
            <button
              className="text-white link-button"
              onClick={props.handleBackButtonClick}
            >
              &laquo; Go Back
            </button>
        </p>
      </div>
    );
  };
  
  export default InternalForm;
  