import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import Auth0Lock from "auth0-lock";
import { auth0settings, tenants } from "./constants/auth0";
import EmailValidator from "email-validator";
import Step1Form from "./components/step1/Form";
import ExternalForm from "./components/step2/ExternalForm";
import InternalForm from "./components/step2/InternalForm";

const App = () => {
  const [state, setState] = useState({
    auth0LoginFormDisplay: "d-none",
    clientID: "",
    email: "",
    errorMessage: "",
    step: 1,
    redirectUrl: "",
    resetPasswordUrl: "",
    userType: "",
    tenant: "",
    loading: false,
  });

  const urlParams = new URLSearchParams(window.location.search);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    changeState(e.currentTarget.id, e.currentTarget.value);
  };

  const changeState = (
    property: string,
    value: string | number | boolean
  ): void => {
    setState((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  const isInternalOrExternal = (email: string): string => {
    return email.includes("@upfitness.com") ? "internal" : "external";
  };

  const handleBackButtonClick = (): void => {
    changeState("step", 1);
  };

  const isLoading = (value: boolean): void => {
    changeState("loading", value);
  };

  const appendStuffToRedirectUrl = (
    redirectUrl: string,
    referrerUrl: string,
    userType: string
  ): string => {

    var append = "type=" + userType + '&referrer=' + referrerUrl;

    return redirectUrl.includes("?")
      ? redirectUrl + "&" + append
      : redirectUrl + "?" + append;
  };

  const getQueryParam = (name: string): string => {
    var value = urlParams.get(name);
    return typeof value === "string" ? value : "";
  };

  const handleInternalLoginClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    isLoading(true);

    window.location.href = process.env.REACT_APP_SAML_URL+"?rt="+encodeURIComponent(state.redirectUrl);
  }

  const handleStep1FormSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    isLoading(true);
    changeState("errorMessage", "");

    if (!EmailValidator.validate(state.email)) {
      isLoading(false);
      changeState("errorMessage", "Please enter a valid email address");
      return;
    }

    var userType = isInternalOrExternal(state.email);
    var resetPasswordUrl = getQueryParam("resetPassword");
    var tenant = tenants[userType];
    var clientID = getQueryParam(userType);
    var redirectUrl = appendStuffToRedirectUrl(
      getQueryParam("redirect"),
      getQueryParam("referrer"),
      userType
    );

    if (userType === "external" && (tenant === "" || clientID === "")) {
      isLoading(false);
      changeState(
        "errorMessage",
        "Unfortunately, you do not have access to ultimate Performances internal tools. You must be a member of the U.P. team with a U.P. email address to log in."
      );
      return;
    }
    
    isLoading(false);
    
    setState((prevState) => ({
      ...prevState,
      tenant: tenant,
      clientID: clientID,
      redirectUrl: redirectUrl,
      resetPasswordUrl: resetPasswordUrl,
      userType: userType,
    }));
  };

  useEffect(() => {

    if (
      (state.userType === 'external' &&
      state.tenant !== "" &&
      state.redirectUrl !== "" &&
      state.clientID !== "") || 
      (state.userType === 'internal' && 
      state.redirectUrl !== "" && 
      state.tenant !== "")
      )
    {
      changeState("step", 2);
    }
  }, [state.tenant, state.redirectUrl, state.clientID, state.userType]);

  useEffect(() => {
    if (state.step === 2 && state.userType === 'external') {
      initAuth0Form();
    }

    if (state.step === 1) {
      changeState("tenant", "");
    }
  }, [state.step]);

  const initAuth0Form = () => { 
    var lock = new Auth0Lock(state.clientID, state.tenant, {
      ...auth0settings,
      prefill: { email: state.email },
      auth: { redirectUrl: state.redirectUrl },
    });
    lock.show();
  };

  return (
    <div className="App">
      {state.step === 1 && (
        <Step1Form
          state={state}
          handleStep1FormSubmit={handleStep1FormSubmit}
          handleInputChange={handleInputChange}
        />
      )}
      {state.step === 2 && state.userType === 'external' && (
        <ExternalForm
          state={state}
          handleBackButtonClick={handleBackButtonClick}
        />
      )}
      {state.step === 2 && state.userType === 'internal' && (
        <InternalForm
          state={state}
          handleBackButtonClick={handleBackButtonClick}
          handleInternalLoginClick={handleInternalLoginClick}
          handleInputChange={handleInputChange}
        />
      )}
    </div>
  );
};

export default App;
