const ExternalForm = (props) => {
  return (
    <div id="step2" className="mb-4">
      <div id="auth0-login-form"></div>
      {props.state.resetPasswordUrl && (
        <p className="mb-4">
          <a className="text-light" href={props.state.resetPasswordUrl}>
            Forgot Password?
          </a>
        </p>
      )}
      <p>
          <button
            className="text-white link-button"
            onClick={props.handleBackButtonClick}
          >
            &laquo; Go Back
          </button>
      </p>
    </div>
  );
};

export default ExternalForm;
