import { Tenants } from "../types";

const auth0settings = {
  allowSignUp: false,
  allowShowPassword: true,
  allowForgotPassword: false,
  avatar: null,
  auth: {
    redirect: true,
    responseMode: "form_post",
  },
  closable: false,
  container: "auth0-login-form",
  languageDictionary: {
    emailInputPlaceholder: "something@youremail.com",
    title: "Log In",
  },
  theme: {
    logo: "up-logo-dark.png",
    primaryColor: "#e40032",
  },
};

const tenants: Tenants = {
  external: "auth-external.ultimateperformance.com",
  internal: "none",
};

export { auth0settings, tenants };
