import { Form, Button } from "react-bootstrap";

const Step1Form = (props) => {
  return (
    <div className="step-form" id="step1">
      <img
        src="up-logo-light.png"
        alt="Ultimate Performance"
        className="logo pb-4"
        width="150px"
      />
      <Form onSubmit={props.handleStep1FormSubmit}>
        <Form.Group controlId="email">
          <Form.Control
            type="email"
            value={props.state.email}
            placeholder="Email address"
            onChange={props.handleInputChange}
          />
        </Form.Group>
        <Button block variant="up-primary" type="submit" className="p-2">
          {props.state.loading === true ? (
            <span>
              <img src="oval.svg" alt="Loading" width="30" />
            </span>
          ) : (
            <span>Continue</span>
          )}
        </Button>
      </Form>
      {props.state.errorMessage !== "" && (
        <div
          className="error-message mt-3 text-medium-dark bg-very-dark border border-secondary rounded p-2"
          dangerouslySetInnerHTML={{
            __html: props.state.errorMessage,
          }}
        ></div>
      )}
      <p className="mt-4">
          <a className="text-white" href={document.referrer}>
            &laquo; Return to Website
          </a>
      </p>
    </div>
  );
};

export default Step1Form;
